import React, { useState, useEffect } from 'react';
import './Home.css';
import Navbar from './Navbar';
import Footer from '.././Footer/Footer';
import backgroundImage from '../../assets/images/SAS_7763.jpg';
import { Link } from 'react-router-dom';
import logo1 from '../../assets/images/logo1.jpg'; 
import logo2 from '../../assets/images/logo2.webp';
import logo3 from '../../assets/images/logo3.jpg';
import logo4 from '../../assets/images/logo4.png'; 
import logo6 from '../../assets/images/logo6.jpeg';
import logo7 from '../../assets/images/logo7.jpg'; 
import logo8 from '../../assets/images/logo8.png';

const CommitmentSection = () => {
  const quotes = [
    "The firm and its lead Advocates have participated in numerous significant transactions and cases in recent years. The following are examples of matters that the firm has recently been involved in: ",
    "Sales & Purchases of a variety of properties in various parts of Kenya, and within a wide ambit in terms of size and value – approximately over Kshs.1 billion cumulatively;",
    "Negotiating Compensation claims and settlements with the Government of Kenya concerning significant land squatter issues along the coast;",
    "Acting as Developer’s Advocates in a Project Development scheme for 52 Apartments Units in Lavington. Successfully secured all necessary project transaction clearances and approvals. Prepared the Agreements for sale and Leases, subsequently registering the latter;",
    "Acting as Developer’s Advocates in a Project Development scheme for 64 Apartment Units in Lavington. Facilitated the acquisition of project transaction approvals, including Change of Use from single-dwelling to multidwelling, and its registration (together with various project stakeholders). Currently engaged in procuring Sectional Titles under the Sectional Properties Act, 2020;",
    "Handling various Succession & Administration issues; 1. Civil Litigation; 2. Joint ventures; 3. Insurance claims",
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  const changeQuote = (direction) => {
    if (direction === 'prev') {
      setCurrentQuoteIndex((prevIndex) => (prevIndex === 0 ? quotes.length - 1 : prevIndex - 1));
    } else {
      setCurrentQuoteIndex((prevIndex) => (prevIndex === quotes.length - 1 ? 0 : prevIndex + 1));
    }
  };

  return (
    <div className="commitment-section">
      <div className="commitment-container">
        <h2>RECENT KEY TRANSACTIONS AND CASES</h2>
        <div className="quote1-container">
          <blockquote>{quotes[currentQuoteIndex]}</blockquote>
          <div className="button-container1">
            <button onClick={() => changeQuote('prev')}>&#9664;</button>
            <button onClick={() => changeQuote('next')}>&#9654;</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
    const [quotes, setQuotes] = useState([
        "Special Approach, Dedicated Attorneys",
        "Excellence in Legal Services",
        "Client-Centric Legal Solutions",
    ]);
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        }, 5000);


        return () => clearInterval(intervalId);
    }, [quotes.length]);

    const [clients, setClients] = useState([
        { name: "Equity Bank Kenya Limited", logo: logo1 },
        { name: "Kenya Airports Authority", logo: logo2 },
        { name: "Discovery Holdings Limited", logo: logo3 },
        { name: "Bismal Investments Limited;" },
        { name: "Longonot Gate Development Company Limited", logo: logo6 },
        { name: "The Mazrui Community of Kilifi and Kwale", logo: logo7 },
        { name: "Msambweni Development Company Limited", logo: logo8 },
    ]);
    const [currentClientsIndex, setCurrentClientsIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentClientsIndex((prevIndex) => (prevIndex + 1) % (clients.length - 2));
        }, 3000);

        return () => clearInterval(intervalId);
    }, [clients.length]);

    return (
        <div className="home-container">
          
            <div className="home-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Navbar />
            
            
                <div className="quote-container">
                    <h3 className="quote">
                        <span className="quote-mark">“</span>{quotes[currentQuoteIndex]}<span className="quote-mark">”</span>
                    </h3>
                    <div className="button-container">
                        <Link to="/Contact" className="button-link">
                            <button className="book-appointment-button">Book an Appointment</button>
                        </Link>
                        <Link to="/Practice" className="button-link">
                            <button className="get-advice-button">Get Expert Advice</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="top-clients-container">
                <div className="top-clients-content">
                    <h2>Top Clients</h2>
                    <div className="client-list">
                        {clients.slice(currentClientsIndex, currentClientsIndex + 3).map((client, index) => (
                            <div key={index} className="client">
                                <img src={client.logo} alt={client.name} className="client-logo" />
                                <div className="client-name">{client.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>  
            <div className="experience-section">
              <div className="experience-container">
                <div className="overlay1"></div>
                <h2>CREATING POSITIVE EXPERIENCES <br /> THROUGH DEDICATION</h2>
                <p>
                  At <strong>M U H O R O & A S S O C I A T E S A D V O C A T E S</strong>, we are dedicated to providing exceptional legal services that create positive experiences for our clients. Our team is committed to delivering outstanding results and personalized attention to every case we handle.
                </p>
                <div className="ovely2"></div>
              </div>
            </div>
            <CommitmentSection />
            <Footer />
        </div>
    );
}

export default Home;
