import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div id='nav'>
            <nav>
                <div className='menu-toggle' onClick={toggleMenu}>
                    <div className={showMenu ? 'close' : 'open'}></div>
                    <div className={showMenu ? 'close' : 'open'}></div>
                    <div className={showMenu ? 'close' : 'open'}></div>
                </div>
                <ul className={showMenu ? 'show' : ''}>
                    <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to='/about'>About</Link>
                    </li>
                    <li>
                        <Link to='/practice'>Practice areas</Link>
                    </li>
                    <li>
                        <Link to='/contact'>Contact</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;
